import { useContext, useEffect, useMemo, useState } from 'react'

import HeaderContext from 'context/headerContext'
import { useTranslation } from 'react-i18next'
import Auxiliary from 'utils/Auxiliary/Auxiliary'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { percentageFormatter2 } from 'utils/formatters/percentageFormatters2'
import Search from 'utils/UI/Inputs/Search'
import DefaultTable from 'utils/UI/Tables/DefaultTable'

const currentYear = new Date().getFullYear()
const lastYear = currentYear - 1
const twoYearsAgo = currentYear - 2
const threeYearsAgo = currentYear - 3

export default function InstrumentReturnsTable({ data }) {
  const { t } = useTranslation(['dashboard', 'common'])
  const colsData = [
    {
      id: 'ticker',
      label: t('Ticker'),
      minWidth: 80
    },
    {
      id: 'name',
      label: t('Nombre'),
      minWidth: 240,
      tooltipValue: (row) => row.name,
      ellipsis: true
    },
    {
      id: 'custom',
      label: t('Custom'),
      minWidth: 80,
      align: 'right',
      render: ({ custom }) => percentageFormatter2((custom - 1) * 100)
    },
    {
      id: 'cagr',
      label: t('CAGR'),
      minWidth: 80,
      align: 'right',
      render: ({ cagr }) => percentageFormatter2((cagr - 1) * 100)
    },
    {
      id: 'vol',
      label: t('Vol'),
      align: 'right',
      render: ({ vol }) =>
        Math.round(vol * 1000) / 1000
          ? percentageFormatter2((vol - 1) * 100)
          : '-'
    },
    {
      id: 'm',
      label: t('1M'),
      align: 'right',
      enablePositiveNegativeColors: true,
      render: ({ m }) => percentageFormatter2((m - 1) * 100)
    },
    {
      id: 'three_m',
      label: t('3M'),
      align: 'right',
      enablePositiveNegativeColors: true,
      render: ({ three_m }) => percentageFormatter2((three_m - 1) * 100)
    },
    {
      id: 'ytd',
      label: t('YTD'),
      align: 'right',
      enablePositiveNegativeColors: true,
      render: ({ ytd }) => percentageFormatter2((ytd - 1) * 100)
    },
    {
      id: `${lastYear}`,
      label: `${lastYear}`,
      align: 'right',
      enablePositiveNegativeColors: true,
      render: ({ [lastYear]: value }) => percentageFormatter2((value - 1) * 100)
    },
    {
      id: `${twoYearsAgo}`,
      label: `${twoYearsAgo}`,
      align: 'right',
      enablePositiveNegativeColors: true,
      render: ({ [twoYearsAgo]: value }) =>
        percentageFormatter2((value - 1) * 100)
    },
    {
      id: `${threeYearsAgo}`,
      label: `${threeYearsAgo}`,
      align: 'right',
      enablePositiveNegativeColors: true,
      render: ({ [threeYearsAgo]: value }) =>
        percentageFormatter2((value - 1) * 100)
    },
    {
      id: 'y',
      label: '1Y',
      align: 'right',
      enablePositiveNegativeColors: true,
      render: ({ y }) => percentageFormatter2((y - 1) * 100)
    },
    {
      id: 'three_y',
      label: '3Y',
      align: 'right',
      enablePositiveNegativeColors: true,
      render: ({ three_y }) => percentageFormatter2((three_y - 1) * 100)
    },
    {
      id: 'five_y',
      label: '5Y',
      align: 'right',
      enablePositiveNegativeColors: true,
      render: ({ five_y }) => percentageFormatter2((five_y - 1) * 100)
    }
  ]
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency } = headerState
  const [rowsData, setRowsData] = useState(null)
  const [search, setSearch] = useState('')

  const tableData = useMemo(() => {
    if (search) {
      return rowsData.filter((x) => {
        return x.name.toLowerCase().includes(search.toLowerCase())
      })
    }
    return rowsData
  }, [rowsData, search])

  useEffect(() => {
    let mounted = true
    if (mounted && data) {
      const parsedData = data.map((item) => ({
        ticker: item.id,
        name: item['name'],
        custom: item.returns.Custom,
        m: item.returns['1M'],
        three_m: item.returns['3M'],
        ytd: item.returns.YTD,
        [lastYear]: item.returns[`${lastYear}`],
        [twoYearsAgo]: item.returns[`${twoYearsAgo}`],
        [threeYearsAgo]: item.returns[`${threeYearsAgo}`],
        y: item.returns['1Y'],
        three_y: item.returns['3Y'],
        five_y: item.returns['5Y'],
        cagr: parseFloat(item.cagr) + 1,
        vol: item.vol && parseFloat(item.vol) + 1
      }))
      setRowsData(parsedData)
    } else {
      setRowsData(null)
    }

    return () => {
      mounted = false
    }
  }, [currency, startDate, endDate, data])

  return (
    <CardWrapper
      title={t('Retornos de activos')}
      action={<Search onChange={setSearch} value={search} />}
    >
      {rowsData ? (
        <Auxiliary>
          <DefaultTable
            colsData={colsData}
            rowsData={tableData}
            maxHeight={false}
          />
          <div
            style={{
              textAlign: 'left'
            }}
          >
            <p>
              * {t('Datos para 3Y y 5Y son retornos promedios anualizados')}
            </p>
          </div>
        </Auxiliary>
      ) : (
        <div></div>
      )}
    </CardWrapper>
  )
}
